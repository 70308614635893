<template>
  <div class="comment-section">
    <template v-if="loading"> <loader></loader></template>
    <template v-if="!loading">
      <div class="insert-comment">
        <input
          class="form-control"
          v-model="commentMessage"
          :placeholder="$t('task.comment.newCommentPlaceholder')"
          type="text"
          id="commentMessage"
        />
        <button class="btn btn-primary" @click="addComment" :disabled="!commentMessage">
          {{ $t('task.comment.addComment') }}
        </button>
      </div>
      <div class="comments" v-if="comments.length > 0">
        <comment v-for="commentInfo in comments" :commentInfo="commentInfo" :key="commentInfo.id"></comment>
      </div>
      <el-pagination
        v-if="comments.length > 0"
        background
        layout="prev, pager, next"
        @current-change="handlePageChange($event)"
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :size="pageSize"
        :total="totalComments"
        hide-on-single-page
        class="m-2"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import Comment from './Comment.vue';
import { mapGetters } from 'vuex';
import tasks from '@app/mixins/tasks';

export default {
  mixins: [tasks],
  components: {
    Comment
  },
  props: {
    taskAssignmentId: {
      type: String
    },
    totalComments: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      commentMessage: '',
      comments: [],
      pageNumber: 1,
      pageSize: 5
    };
  },
  created() {
    this.getTaskComments();
  },
  methods: {
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getTaskComments();
    },
    getTaskComments() {
      this.loading = true;
      this.$store
        .dispatch('tasks/getTaskCommentsById', {
          taskAssignmentId: this.taskAssignmentId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        })
        .then((comments) => {
          this.comments = comments;
          const userIds = comments.map((comment) => comment.createdById);
          if (userIds.length) {
            this.$store.dispatch('tasks/getUsers', userIds).then(() => (this.loading = false));
          } else {
            this.loading = false;
          }
        });
    },
    addComment() {
      this.$store
        .dispatch('tasks/postTaskComments', {
          taskAssignmentId: this.taskAssignmentId,
          message: this.commentMessage
        })
        .then(
          () => {
            this.pageNumber = 1;
            this.getTaskComments();
            this.$emit('extraComment');
            this.commentMessage = '';
            this.$notify({
              title: 'Success',
              message: this.$t('notification.comment.success'),
              type: 'success',
              position: 'bottom-right',
              customClass: 'success'
            });
          },
          (err) => {
            this.$notify({
              title: 'Error',
              message: this.$t('notification.error'),
              type: 'error',
              position: 'bottom-right',
              customClass: 'error'
            });
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.comment-section {
  background-color: white;
  .insert-comment {
    padding: 16px;
    display: grid;

    grid-template-columns: 4fr 1fr;
    grid-column-gap: 16px;
  }

  .comments {
    padding: 16px;
    background-color: #f7f8f8;
  }
}
</style>
