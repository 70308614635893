<template>
  <div class="container pb-5" id="tasks">
    <template v-if="loading"><loader></loader></template>
    <template v-else>
      <div ref="tasks">
        <task-onboardee-list
          :title="$t('task.title.activeTasks')"
          :tasks="todos"
          :initialCollapsed="false"
          :allowCancel="false"
          :interactionAllowed="true"
        ></task-onboardee-list>
        <task-onboardee-list
          :title="$t('task.title.completedTasks')"
          :tasks="done"
          :interactionAllowed="false"
        ></task-onboardee-list>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Tabs, TabPane } from 'element-ui';
import tasks from '@app/mixins/tasks';
import TaskOnboardeeList from '../../components/tasks/TaskOnboardeeList.vue';

export default {
  mixins: [tasks],
  components: {
    TaskOnboardeeList,
    elTabs: Tabs,
    elTabPane: TabPane
  },
  created() {
    setTimeout(() => this.$store.dispatch('dashboard/getTodoData'), 200);
  },
  computed: {
    ...mapGetters('dashboard', ['dashboardData']),
    ...mapGetters('settings', ['style']),
    iconStyle() {
      return {}; // do we need to allow this color to be customized...?
      return {
        color: this.style.taskIconColor
      };
    }
  },
  data() {
    return {
      activeTab: 'todo',
      loading: false,
      counter: 30,
      start: 0,
      taskIds: []
    };
  }
};
</script>

<style lang="scss">
#tasks {
  .el-tabs__header {
    margin: 0 !important;
  }

  .el-tabs__nav {
    width: 100%;
  }

  .el-tabs__nav div.el-tabs__item {
    padding: 0 20px;
    width: 50% !important;
  }

  .el-tabs__item.is-top {
    vertical-align: super;
  }

  .list-group-item {
    display: flex;
    align-items: center;
  }
}
</style>
