<template>
  <div class="attached-files-section">
    <template v-if="loading">
      <loader />
    </template>
    <template v-if="!loading">
      <div class="files" v-if="files.length > 0">
        <el-table
          ref="taskTable"
          :data="files"
          :default-sort="{ prop: 'title', order: 'descending' }"
          stripe
          style="width: 100%"
          :empty-text="$t('task.attachedFiles.emptyTable')"
        >
          <el-table-column :label="$t('task.table.attachedFileName')" prop="title" min-width="25" sortable>
            <template slot-scope="scope">
              {{ scope.row.orginalFileName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('task.table.uploadDate')" min-width="25" prop="dueDateOffset" sortable>
            <template slot-scope="scope">
              {{ getReadableUploadDate(scope.row.uploadedAt) }}
            </template>
          </el-table-column>
          <el-table-column align="right" id="dropdown" min-width="10">
            <template slot-scope="scope">
              <el-dropdown @command="handleAction($event, scope.row.id, scope.row.orginalFileName)" trigger="click">
                <i class="material-icons">more_horiz</i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="downloadFile">{{ $t('task.action.downloadFile') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tasks from '@app/mixins/tasks';

export default {
  mixins: [tasks],
  props: {
    taskDefinitionId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      files: []
    };
  },
  created() {
    this.loading = true;
    this.getAllTaskFiles(this.taskDefinitionId).then(
      (files) => {
        this.loading = false;
        this.files = files;
        const fileIds = files.map((file) => file.id);
      },
      () => {
        this.loading = false;
      }
    );
  },
  methods: {
    getAllTaskFiles(taskDefinitionId) {
      return this.$store.dispatch('tasks/getFiles', taskDefinitionId);
    },

    getFileById(fileId, fileName) {
      return this.$store
        .dispatch('tasks/getFileById', fileId)
        .then((file) => file.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },

    getReadableUploadDate(dateString) {
      const date = new Date(dateString);
      const monthName = [
        'month.january',
        'month.february',
        'month.march',
        'month.april',
        'month.may',
        'month.june',
        'month.july',
        'month.august',
        'month.september',
        'month.octobre',
        'month.november',
        'month.december'
      ];

      const currentMonthName = this.$t(monthName[date.getMonth()]);
      return `${date.getDate()} ${currentMonthName} ${date.getFullYear()}`;
    },

    handleAction(action, fileId, fileName) {
      switch (action) {
        case 'downloadFile':
          this.getFileById(fileId, fileName);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.attached-files-section {
  background-color: white;

  .files {
    padding: 16px;
    background-color: #f7f8f8;
  }
}
</style>
