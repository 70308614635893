<template>
  <div class="taskOnboardeeList" v-if="!loading">
    <h2 @click="collapsed = !collapsed" class="tasks-category" :aria-expanded="String(!collapsed)" tabindex="0">
      {{ title }} <span>({{ numberOfTasks }})</span>
    </h2>
    <div v-if="!collapsed" class="tasks">
      <task-onboardee
        :task="task"
        v-for="task in filteredTasks"
        :key="task.id"
        :interactionAllowed="interactionAllowed"
        :allowCancel="allowCancel"
      ></task-onboardee>
    </div>
  </div>
</template>

<script>
import TaskOnboardee from './TaskOnboardee.vue';

export default {
  components: {
    TaskOnboardee
  },
  data() {
    return {
      collapsed: true,
      loading: true
    };
  },
  props: {
    tasks: {
      type: Array
    },
    title: {
      type: String
    },
    interactionAllowed: {
      type: Boolean
    },
    allowCancel: {
      type: Boolean
    },
    search: {
      type: String
    },
    initialCollapsed: {
      type: Boolean
    }
  },
  created() {
    this.loading = false;
    this.collapsed = this.initialCollapsed;
  },
  computed: {
    filteredTasks() {
      let filteredTasks;
      if (this.search) {
        filteredTasks = this.tasks.filter((task) => {
          return task.translations[0].title && task.translations[0].title.includes(this.search);
        });
      } else {
        filteredTasks = this.tasks;
      }
      return filteredTasks;
    },
    numberOfTasks() {
      return this.filteredTasks.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.taskOnboardeeList {
  margin-top: 64px;

  h2 {
    cursor: pointer;
  }
}

.tasks-category {
  padding-left: 15px;
}
</style>
