import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  computed: {
    ...mapGetters('settings', ['company'])
  },
  data() {
    return {
      userEmail: null,
      talentechId: null
    };
  },
  methods: {
    track(eventName) {
      /**
       * @TODO enable planhat tracking
       */
      return;
      if (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.email !== null &&
        (this.$store.state.auth.user.email.match(/talmundo/g) || this.$store.state.auth.user.email.match(/talentech/g))
      ) {
        return;
      }
      if (this.$store.state.auth.user && !this.$store.state.auth.user.isAdmin) {
        let queryString = new URL(window.location.href);
        this.userEmail = `onboardee@${queryString.hostname.split('.')[0]}.com`;
      } else if (this.$store.state.auth.user) {
        this.userEmail = this.$store.state.auth.user.email;
      }
      if (this.$store.state.auth.talentechTenantId !== null && this.$store.state.auth.planhatTokens.length) {
        this.$store.state.auth.planhatTokens.map((token) => {
          axios.post(`https://analytics.planhat.com/analytics/${token}`, {
            email: this.userEmail,
            action: eventName,
            weight: '1'
          });
        });
      }
    }
  }
};
