import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('dashboard', ['dashboardData', 'formsV2Enabled', 'finishedTasks']),
    formV2Available() {
      return this.dashboardData && this.dashboardData.formsV2ToDo && this.formsV2Enabled;
    },
    todos() {
      let formsV2Todo = [];

      if (this.formV2Available) {
        formsV2Todo = this.dashboardData.formsV2ToDo
          .filter((formV2Todo) => !formV2Todo.isFinished)
          .map(this.mapFormV2Todos);
      }

      let adminFormsTodo = [];
      if (this.dashboardData && this.dashboardData.formTodoLink.length > 0) {
        adminFormsTodo = this.dashboardData.formTodoLink.map(this.mapAdminFormTodos);
      }

      let result = this.dashboardData
        ? [...formsV2Todo, ...this.dashboardData.todoList.filter((i) => !i.isFinished), ...adminFormsTodo]
        : [];

      result = [...new Set(result)];

      return this.sortByDateSoonestFirst(result);
    },
    done() {
      let formsV2Todo = [];
      if (this.formV2Available) {
        formsV2Todo = this.dashboardData.formsV2ToDo
          .filter((formV2Todo) => formV2Todo.isFinished)
          .map(this.mapFormV2Todos);
      }

      let result = this.dashboardData
        ? [...formsV2Todo, ...this.dashboardData.todoList.filter((i) => i.isFinished)]
        : [];

      result = result.concat(this.finishedTasks);
      result = [...new Set(result)];

      return this.sortByDateSoonestFirst(result);
    },
    tasks() {
      let formsV2Todo = [];
      if (this.formV2Available) {
        formsV2Todo = this.dashboardData.formsV2ToDo;
      }

      let result = this.dashboardData
        ? [...formsV2Todo, ...this.dashboardData.todoList, ...this.dashboardData.formTodoLink]
        : [];

      result = [...new Set(result)];

      return result;
    }
  },
  methods: {
    mapFormV2Todos(formV2Todo) {
      return {
        title: formV2Todo.formName,
        name: formV2Todo.formId,
        type: 9,
        route: `/learning-page/${formV2Todo.moduleId}/${formV2Todo.learningPageId}/${formV2Todo.formId}`
      };
    },
    mapAdminFormTodos(adminFormTodo) {
      return {
        route: `/form/${adminFormTodo.id}`,
        title: adminFormTodo.title,
        name: adminFormTodo.id
      };
    },
    sortByDateSoonestFirst(tasksToSort) {
      return tasksToSort.slice().sort((a, b) => {
        let x = a.dueDate;
        let y = b.dueDate;

        // Sort alphabetically on title when task has no dueDate
        if (x == undefined) {
          x = a.title || '';
          x = x.charAt(0).toUpperCase() + x.slice(1);
        }
        if (y == undefined) {
          y = b.title || '';
          y = y.charAt(0).toUpperCase() + y.slice(1);
        }

        return x == y ? 0 : x > y ? 1 : -1;
      });
    }
  }
};
